<template>
  <div class="contribution-card-detail">
    <van-sticky>
      <van-config-provider>
        <van-nav-bar :title="$t('title.detail')" left-arrow @click-left="goBack" :border="false"></van-nav-bar>
      </van-config-provider>
    </van-sticky>
    <div class="order-list">
      <div v-if="!$AppData.config.themeType" class="journal-block white">
        <div class="card-title">{{cardDetail.name}}</div>
        <div class="journal-detail">
          <van-cell :title="$tc('field.useDay',cardDetail.useDay)" :value="cardDetail.useDay" :border="false" class="journal-detail-row" title-class="journal-label" value-class="coin-price" />
          <van-cell :title="$t('field.dayContribution')" :value="cardDetail.contribution" :border="false" class="journal-detail-row" title-class="journal-label" value-class="coin-price" />
          <van-cell :title="$t('field.needDeposit')" :value="`${cardDetail.deposit}${$AppData.config.appCoin}`" :border="false" class="journal-detail-row" title-class="journal-label" value-class="coin-price" />
          <van-cell :title="$t('field.balance')" :value="`${accountInfo.balanceStr}${$AppData.config.appCoin}`" :border="false" class="journal-detail-row" title-class="journal-label" value-class="coin-price" />
        </div>
      </div>
      <div v-else>
        <div
          :class="`card-block ${cardDetail.showStatus == 2 ? 'soldout' : ''}`"
          :style="`background: url(${cardDetail.imgUrl}) 0 0/100% auto no-repeat;`"
        >
          <span class="card-name">{{cardDetail.name}}</span>
          <div class="detail-box">
            <div class="detail-col col1">
              <p class="detail-label">{{cardDetail.deposit}} {{$AppData.config.appCoin}}</p>
              <p class="detail-value">{{$t('field.needDeposit')}}</p>
            </div>
            <div class="divider"></div>
            <div class="detail-col col2">
              <p class="detail-label">{{cardDetail.useDay}}</p>
              <p class="detail-value">{{$tc('field.useDay',cardDetail.useDay)}}</p>
            </div>
            <div class="divider"></div>
            <div class="detail-col col3">
              <p class="detail-label">{{cardDetail.contribution}}</p>
              <p class="detail-value">{{$t('field.dayContribution')}}</p>
            </div>
          </div>
        </div>
        <p class="balance-title">{{$t('field.balance')}}</p>
        <p class="balance-value"><span>{{accountInfo.balanceStr}}</span>{{$AppData.config.appCoin}}</p>
      </div>
      <van-button v-if="cardDetail.showStatus == 2" type="primary"  size="large" block disabled>{{$t('button.soldOut').toUpperCase()}}</van-button>
      <van-button v-else-if="cardDetail.showStatus == 1" type="primary"  size="large" block disabled>{{$t('button.purchased').toUpperCase()}}</van-button>
      <van-button v-else-if="cardDetail.showStatus == 3" type="primary"  size="large" block disabled>{{$t('button.notReachRank').toUpperCase()}}</van-button>
      <van-button
        v-else
        size="large"
        type="primary"
        block
        @click="handleBuyContributionCard"
      >{{$t('button.purchase').toUpperCase()}}</van-button>
      <div v-if="cardDetail.described" class="tip-text">{{cardDetail.described}}</div>
    </div>
  </div>
</template>

<script>
import i18n from '@/assets/lang/index';
import { queryAccountInfo, buyContributionCard } from "@/api";

export default {
  data() {
    return {
      cardDetail: JSON.parse(localStorage.getItem('cardDetail')),
      accountInfo: {},
    };
  },
  
  mounted() {
    this.fetchAccountInfo();
  },
  
  unmounted() {
    if (this.coldDownInterval) {
      clearInterval(this.coldDownInterval);
    }
  },

  methods: {
    fetchAccountInfo() {
      queryAccountInfo().then(res => {
        this.accountInfo = res.data || {};
      });
    },
    handleBuyContributionCard() {
      this.$myDialog.confirm({
        title: i18n.global.t('alert.warnning'),
        message: i18n.global.t('alert.purchase'),
      })
      .then(() => {// on confirm
        buyContributionCard({ id: this.cardDetail.id }).then(res => {
          this.goBack();
        });
      })
      .catch(() => {});
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less">
.contribution-card-detail {
  .journal-block {
    background: #fff;
    border-radius: 10px;
    margin-bottom: 16px;
  }
  .card-title {
    font-size: 16px;
    line-height: 30px;
    padding: 6px 14px;
    text-align: center;
    background: #fff;
    border-radius: 10px 10px 0 0;
    border-bottom: 1px solid var(--van-border-color);
  }
  .tip-text {
    white-space: pre-wrap;
    word-break: break-all;
    text-align: left;
    margin-top: 20px;
    font-size: 14px;
  }
}
.theme1 .contribution-card-detail {
  .order-list {
    padding: 12px;
  }
  .card-title {
    font-size: 16px;
    line-height: 30px;
    padding: 0;
    color: @light-primary-color;
    text-align: left;
    background: transparent;
    border-radius: 0 ;
    border-bottom: none;
  }
  .van-button {
    width: auto;
    height: 36px;
    min-width: 120px;
    padding: 0 20px;
    border-radius: 25px;
    margin: 0 auto;
    background: linear-gradient(90deg, @light-primary-color, @primary-color);
    border: none;
  }
  .balance-title {
    text-align: center;
    font-size: 18px;
    margin: 20px 0 8px;
  }
  .balance-value {
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
    span {
      margin-right: 6px;
      font-size: 24px;
    }
  }
  .tip-text {
    min-height: 80px;
    padding: 16px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 16px 0px #EBEBEB;
  }
  .card-block {
    min-height: 152px;
    padding: 10px;
    margin-bottom: 14px;
    border-radius: 4px;
    box-sizing: border-box;
    .card-name {
      display: inline-block;
      line-height: 27px;
      padding: 0 8px;
      font-size: 16px;
      font-weight: 500;
      margin: 5px 0 32px;
      color: #fff;
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.16);
    }
    .detail-box {
      display: flex;
      align-items: center;
      color: #fff;
      font-weight: 500;
      margin: 0 4px;
      padding: 12px 0;
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.16);
      .detail-col {
        text-align: center;
        &.col1 { width: 104px; }
        &.col2 { width: 98px; }
        &.col3 { flex: 1; }
        .detail-label {
          min-height: 21px;
          font-size: 18px;
          margin-bottom: 8px;
        }
        .detail-value {
          font-size: 12px;
        }
      }
    }
    .divider {
      width: 1px;
      height: 28px;
      background: #fff;
    }
    &.soldout {
      .card-name, .detail-box { background: rgba(255, 255, 255, 0.25); }
    }
  }
}
</style>
